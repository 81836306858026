import React from 'react';

function DocumentList({payload, handleOnView, handleOnApprove}) {
  const userRole = window.localStorage.getItem('userRole')
  let hidden = userRole ? !userRole.includes('director') : false

  return(
    <table className='min-w-full divide-y divide-gray-200'>
      <thead className='bg-gray-100 align-middle border-b boder-gray-200'>
        <tr className='h-12'>
          <th scope='col'>Nomor Dokumen</th>
          <th scope='col'>Bulan</th>
          <th scope='col'>Tahun</th>
          <th scope='col'>Jumlah Pembayaran</th>
          <th scope='col'>Disetujui Oleh</th>
          <th scope='col'>Disetujui Tanggal</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {payload.map(row => (
        <tr key={row._id} className='h-12 border-b'>
          <td className='text-left'>{row._id}</td>
          <td className='text-center'>{row.month}</td>
          <td className='text-center'>{row.year}</td>
          <td className='text-right'>Rp {row.totalPayments ? row.totalPayments.toLocaleString() : null}</td>
          <td className='text-center'>{row.approved_by}</td>
          <td className='text-center'>{row.approved_at ? new Date(row.approved_at).toLocaleString("id-ID", {dateStyle: 'short'}) : null}</td>
          <td>
            <div className='grid grid-cols-2'>
              <div>
                <button className='bg-white' onClick={() => handleOnView(row)} >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </button>
              </div>
              <div hidden={row.approved_by ? true : hidden} >
                <button className='bg-white' onClick={() => handleOnApprove(row)} >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </button>
              </div>
            </div>
          </td>
        </tr>
        ))
      }
      </tbody>
    </table>
  )
}

export default DocumentList