export function getPayroll() {
  return dispatch => {
    dispatch(getToken())
  }
}

function getToken() {
  return dispatch => {
    const tokenURL =
      'https://account.makaramas.com/auth/realms/MM_AIS/protocol/openid-connect/token';

    fetch(tokenURL, {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'client_credentials',
        client_id: 'payroll',
        client_secret: '6aa28a13-9570-4a34-bd36-554b8ba9c4c1',
        scope: 'profile email',
      }).toString(),
    })
      .then(res => res.json())
      .then(data => {
        dispatch(fetchPayroll(data));
      })
      .catch(e => {
        dispatch(getPayrollFailed(e));
      });
  };
}

function fetchPayroll(data) {
  return dispatch => {
    dispatch(getPayrollStarted());
    const url = 'https://payroll.makaramas.com/api/document';

    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + data.access_token,
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then(res => res.json())
      .then(data => {
        dispatch(getPayrollSuccess(data));
      })
      .catch(e => {
        dispatch(getPayrollFailed(e));
      });
  };
}

function getPayrollStarted() {
  return {
    type: 'GET_PAYROLL_STARTED',
  };
}

function getPayrollSuccess(documents) {
  return {
    type: 'GET_PAYROLL_SUCCESS',
    payload: documents,
  };
}

function getPayrollFailed(error) {
  return {
    type: 'GET_PAYROLL_FAILED',
    payload: {
      error,
    },
  };
}

export function clearPayroll() {
  return {
    type: 'CLEAR_PAYROLL',
  };
}