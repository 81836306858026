const INITIAL_STATE = {
  document: null,
  isSaving: true,
  error: null,
};

export default function approvePayrollReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'APPROVE_PAYROLL_STARTED':
      return {
        ...state,
        isSaving: true,
      };

    case 'APPROVE_PAYROLL_SUCCESS':
      return {
        ...state,
        isSaving: false,
        error: null,
        document: action.payload,
      };

    case 'APPROVE_PAYROLL_FAILED':
      return {
        ...state,
        isSaving: false,
        error: action.payload.error,
      };
    
    default:
      return state;
  }
}