import React from 'react'
import PayrollPage from '../payroll/index'
import EmployeePage from '../employee/index'
import { Route, Switch } from 'react-router'
import { Link as RouterLink } from 'react-router-dom';

export default function DashboardPage() {
  return(
    <div>
      <div className="bg-gray-100 h-24 shadow-sm">
        <div className="p-4 flex-1 flex justify-between">
          <div>
            <img src='./logo.png' className='h-16' alt='MM Log' />
          </div>
          <div className='block w-48'>
            
          </div>
          <div className='block w-24 center mt-6'>
            <RouterLink to ="/payroll">
              <h2>Payroll</h2>
            </RouterLink>
          </div>
          <div className='block w-24 center mt-6'>
            <RouterLink to ="/employee">
              <h2>Employees</h2>
            </RouterLink>
          </div>
          <div className='ml-auto mt-6'>
            <h1>Payroll Management System</h1>
          </div>
        </div>
      </div>
      <div>
        <div className='m-2 p-4 shadow-md rounded-lg'>
          <Switch>
            <Route exact path="/" component={PayrollPage} />
            <Route exact path="/payroll" component={PayrollPage} />
            <Route exact path="/employee" component={EmployeePage} />
          </Switch>
        </div>
      </div>
    </div>
  )
}