const INITIAL_STATE = {
  employee: null,
  error: null,
  isSaving: true,
};

export default function postEmployeeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'POST_EMPLOYEE_STARTED':
      return {
        ...state,
        isSaving: true,
      };

    case 'POST_EMPLOYEE_SUCCESS':
      return {
        ...state,
        isSaving: false,
        error: null,
        employee: action.payload,
      };

    case 'POST_EMPLOYEE_FAILED':
      return {
        ...state,
        isSaving: false,
        error: action.payload.error,
      };

    case 'CLEAR_EMPLOYEE':
      return {
        ...state,
        isSaving: false,
        error: null,
        employee: null,
      };

    default:
      return state;
  }
}