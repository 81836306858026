import React from 'react'

export default function EmployeeList({payload, handleOnEdit, handleOnDelete, handleOnAddEmployee}) {
  return(
    <div className='md:grid md:grid-cols-4 md:gap-6'>
      <div className='col-start-2 col-span-2'>
        <button 
          type="button"
          className="w-full mb-5 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => handleOnAddEmployee([])}
        >
          Tambah Pegawai Baru
        </button>
        <table className='min-w-full align-middle divide-y divide-gray-200'>
          <thead className='bg-gray-100 align-middle border-b boder-gray-200'>
            <tr className='h-12'>
              <th scope='col' hidden>ID</th>
              <th scope='col'>Nama Pegawai</th>
              <th scope='col'>Email</th>
              <th scope='col'>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {payload.map(row => (
            <tr key={row._id} className='h-12 border-b'>
              <td hidden className='text-left'>{row._id}</td>
              <td className='text-left'>{row.employee_name}</td>
              <td className='text-center'>{row.email}</td>
              <td className='text-center'>{row.status === true ? "Aktif" : "Tidak Aktif"}</td>
              <td>
                <div className='grid grid-cols-2'>
                  <div>
                    <button className='bg-white' onClick={() => handleOnEdit(row)}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                      </svg>
                    </button>
                  </div>
                  <div>
                    <button className='bg-white' onClick={() => handleOnDelete(row)}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            ))
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}