import DashboardPage from './pages/dashboard';
import { BrowserRouter as Router } from 'react-router-dom'
import { useEffect, useState } from 'react';
import keycloak from './core/config/keycloak';

function App() {
  const [authenticated, setAuthenticated] = useState(false)

  useEffect(() => {
    async function keycloakCheck(){
      await keycloak.init({onLoad: 'login-required'})
        .then(authenticated => {
          setAuthenticated(authenticated)
          authenticated === true ? window.localStorage.setItem('userName', keycloak.tokenParsed.preferred_username) : window.localStorage.setItem('userName', null)
          authenticated === true ? window.localStorage.setItem('userRole', keycloak.tokenParsed.realm_access.roles) : window.localStorage.setItem('userRole', null)
        })
    }
    keycloakCheck()
  },[])

  // keycloak functions
  keycloak.onAuthSuccess = function() {
    window.localStorage.setItem('userName', keycloak.tokenParsed.preferred_username)
    window.localStorage.setItem('userToken', keycloak.token)
  }

  keycloak.onAuthRefreshSuccess = function() {
    window.localStorage.setItem('userName', keycloak.tokenParsed.preferred_username)
    window.localStorage.setItem('userToken', keycloak.token)
  }

  keycloak.onAuthRefreshError = function() {
    keycloak.clearToken()
    keycloak.login()
  }

  keycloak.onTokenExpired = function() {
    keycloak.updateToken(300)
    .then((refreshed) => {
      if (refreshed) {
        window.localStorage.setItem('userToken', keycloak.token)
        window.localStorage.setItem('userName', keycloak.tokenParsed.preferred_username)
        console.log('token refreshed')
      } else {
        console.log('token not refreshed')
      }
    })
    .catch(error => {
      console.log('failed to refresh token')
      keycloak.clearToken()
    })
  }

  return (
    <Router>
      <DashboardPage />
    </Router>
  );
}

export default App;
