import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getEmployee } from '../../shared/actions/GetEmployeeAction';
import { clearEmployee, postEmployee } from '../../shared/actions/PostEmployeeAction';
import EmployeeForm from './EmployeeForm';
import EmployeeList from './EmployeeList';

function EmployeePage(props) {
  const [isEdit, setEdit] = useState(false)
  const [content, setContent] = useState(null)
  const {isLoading} = props
  const {employees} = props

  useEffect(() => {
    if (isLoading === true) {
      props.getEmployee()
    }
  },[isLoading])

  function handleOnEdit(item) {
    setContent(item)
    setEdit(true)
  }

  function handleOnChange(e) {
    let name = e.target.name
    let value = e.target.value
    if (name === "dependents" && value > 0){
      setContent({...content,
        dependents: parseInt(value)
      })
    }
    else if (name === "dependents" && value === 0){
      setContent({...content,
        dependents: 0
      })
    }
    else if (name === "hired_date") {
      setContent({...content,
        [name]: value
      })
    }
    else if (name === "termination_date") {
      setContent({...content,
        [name]: value
      })
    }
    else {
      setContent({...content,
        [name]: value
      })
    }
  }

  function handleOnDelete(item) {
    let newItem = {...item, status: false}
    props.postEmployee(newItem)
    setEdit(false)
    setContent(null)
    props.clearEmployee()
    props.getEmployee()
  }

  function handleOnCancel(item) {
    setEdit(false)
  }

  function handleOnSave() {
    props.postEmployee(content)
    setEdit(false)
    setContent(null)
    props.clearEmployee()
    props.getEmployee()
  }

  if (isEdit === false){
    return(
      <EmployeeList payload={employees} handleOnEdit={handleOnEdit} handleOnDelete={handleOnDelete} handleOnAddEmployee={handleOnEdit} />
    )
  }
  else if (isEdit === true){
    return(
      <EmployeeForm payload={content} handleOnCancel={handleOnCancel} handleOnChange={handleOnChange} handleOnSave={handleOnSave} />
    )
  }
}

function mapDispatchtoProps(dispatch) {
  return {
    getEmployee: () => {
      dispatch(getEmployee())
    },
    postEmployee: (content) => {
      dispatch(postEmployee(content))
    },
    clearEmployee: (content) => {
      dispatch(clearEmployee(content))
    }
  }
}

function mapStateToProps(state) {
  return {
    employees: state.getEmployee.employees,
    isLoading: state.getEmployee.isLoading,
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(EmployeePage);