const INITIAL_STATE = {
  document: null,
  entries: [],
  isSaving: true,
  error: null,
};

export default function postPayrollReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'NEW_DOCUMENT':
      return {
        ...state,
        document: action.payload
      }
    
    case 'EDIT_DOCUMENT':
      return {
        ...state,
        document: action.payload.document,
        entries: action.payload.entries
      }
    
    case 'VIEW_ENTRIES':
      return {
        ...state,
        entries: action.payload
      }
      
    case 'ADD_ENTRIES':
      return {
        ...state,
        document: {...document,
          entries: [
            ...state.entries,
            action.payload
          ]
        },
        entries: [
          ...state.entries,
          action.payload
        ]
      };
    
    case 'EDIT_ENTRIES':
      if (state.entries.map(check => check.id === action.payload.id).filter(item => item === true).length > 0 ) {
        let newEntries = state.entries.map(entry => {
          if(entry.id === action.payload.id) {
            return (
              action.payload
            )
          }
          return {...entry}
        })
        
        return {
          ...state,
          document: {...state.document,
            entries: newEntries,
            totalPayments: newEntries.filter(entry => entry.total !== undefined).map(item => item.total).reduce((a,b) => Number(a) + Number(b), 0)
          },
          entries: newEntries
        }
      }
      else {
        return {
          ...state,
          document: {...state.document,
            entries: [
              ...state.document.entries,
              action.payload
            ],
            totalPayments: state.entries && state.entries.length > 0 ? Number(state.entries.filter(entry => entry.total !== undefined).map(item => item.total).reduce((a,b) => Number(a) + Number(b), 0)) + Number(action.payload.total) : Number(action.payload.total)
          },
          entries: [
            ...state.entries,
            action.payload
          ]
        };
      }
      

    case 'REMOVE_ENTRIES':
      let newEntries = state.entries.filter(entry => entry.id !== action.payload.id)
      return {
        ...state,
        document: {...state.document, entries: newEntries, totalPayments: newEntries  ? newEntries.filter(entry => entry.total !== undefined).map(item => item.total).reduce((a,b) => Number(a) + Number(b), 0) : 0},
        entries: newEntries,
      }

    case 'POST_PAYROLL_STARTED':
      return {
        ...state,
        isSaving: true,
      };

    case 'POST_PAYROLL_SUCCESS':
      return {
        ...state,
        isSaving: false,
        error: null,
        document: action.payload,
      };

    case 'POST_PAYROLL_FAILED':
      return {
        ...state,
        isSaving: false,
        error: action.payload.error,
      };

    case 'CLEAR_PAYROLL':
      return {
        ...state,
        isSaving: false,
        error: null,
        document: null,
        entries: []
      };

    default:
      return state;
  }
}