import React, { useEffect, useState } from 'react'

export default function EntryForm({disabled, payload, employees, handleOnChange, handleOnCancel, handleOnSave}){
  const [tunjangan, setTunjangan] = useState(payload.tunjangan || [])
  const [additions, setAdditions] = useState(payload.additions || [])
  const [deductions, setDeductions] = useState(payload.deductions || [])

  useEffect(() => {
    let totalTunjangan = Number(tunjangan.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalAdditions = Number(additions.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalDeductions = Number(deductions.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let total = Number(payload.salary) + totalTunjangan + totalAdditions - totalDeductions
    handleOnChange(null, null, null, null, total)
  },[])

  // Pengaturan Tunjangan
  function handleOnChangeTunjangan(e, idx) {
    let name = e.target.name
    let value = name === 'amount' ?  Number(e.target.value) : e.target.value
    tunjangan[idx] = {...tunjangan[idx], [name]: value}
    let totalTunjangan = Number(tunjangan.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalAdditions = Number(additions.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalDeductions = Number(deductions.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let total = Number(payload.salary) + totalTunjangan + totalAdditions - totalDeductions
    handleOnChange(null, tunjangan, null, null, total)
  }

  function handleOnAddTunjangan() {
    setTunjangan([
      ...tunjangan,
      {name: undefined, amount: 0}
    ])
  }

  function handleOnDeleteTunjangan(idx){
    let result = tunjangan.filter(item => tunjangan.indexOf(item) !== idx)
    setTunjangan(result)
    let totalTunjangan = Number(result.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalAdditions = Number(additions.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalDeductions = Number(deductions.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let total = Number(payload.salary) + totalTunjangan + totalAdditions - totalDeductions
    handleOnChange(null, result, null, null, total)
  }

  // Pengaturan Penambahan
  function handleOnChangeAdditions(e, idx) {
    let name = e.target.name
    let value = name === 'amount' ?  Number(e.target.value) : e.target.value
    additions[idx] = {...additions[idx], [name]: value}
    let totalTunjangan = Number(tunjangan.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalAdditions = Number(additions.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalDeductions = Number(deductions.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let total = Number(payload.salary) + totalTunjangan + totalAdditions - totalDeductions
    handleOnChange(null, null, additions, null, total)
  }

  function handleOnAddAdditions() {
    setAdditions([
      ...additions,
      {name: undefined, amount: 0}
    ])
  }

  function handleOnDeleteAdditions(idx){
    let result = additions.filter(item => additions.indexOf(item) !== idx)
    setAdditions(result)
    let totalTunjangan = Number(tunjangan.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalAdditions = Number(result.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalDeductions = Number(deductions.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let total = Number(payload.salary) + totalTunjangan + totalAdditions - totalDeductions
    handleOnChange(null, null, result, null, total)
  }

  // Pengaturan Pengurangan
  function handleOnChangeDeductions(e, idx) {
    let name = e.target.name
    let value = name === 'amount' ?  Number(e.target.value) : e.target.value
    deductions[idx] = {...deductions[idx], [name]: value}
    let totalTunjangan = Number(tunjangan.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalAdditions = Number(additions.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalDeductions = Number(deductions.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let total = Number(payload.salary) + totalTunjangan + totalAdditions - totalDeductions
    handleOnChange(null, null, null, deductions, total)
  }

  function handleOnAddDeductions() {
    setDeductions([
      ...deductions,
      {name: undefined, amount: 0}
    ])
  }

  function handleOnDeleteDeductions(idx){
    let result = deductions.filter(item => deductions.indexOf(item) !== idx)
    setDeductions(result)
    let totalTunjangan = Number(tunjangan.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalAdditions = Number(additions.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let totalDeductions = Number(result.map(entry => entry.amount).reduce((a,b) => Number(a) + Number(b), 0))
    let total = Number(payload.salary) + totalTunjangan + totalAdditions - totalDeductions
    handleOnChange(null, null, null, result, total)
  }
  
  return(
    <div className='md:grid md:grid-cols-4 md:gap-6'>
      <div className='col-start-2 col-span-2'>
        <div>
          <h1 className="text-lg font-medium leading-6 text-gray-900">
            Remuneration Form
          </h1>

          <div className='grid grid-cols-2 gap-6 mt-6'>
            <div>
              <label className='block text-sm font-medium text-gray-700 pt-2'>
                Nama
              </label>
            </div>
            <div>
              <select
                name="employee_id"
                className='block text-sm w-full font-medium text-gray-700'
                value={payload.id ? payload.id : employees[0]._id}
                disabled={disabled}
                onChange={(e) => handleOnChange(e)}
              >
                {employees.filter(entry => entry.status === true).map(employee => (
                  <option value={employee._id}>{employee.employee_name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-6 mt-3'>
            <div>
              <label className='block text-sm font-medium text-gray-700 pt-2'>
                Gaji Pokok
              </label>
            </div>
            <div>
              <input
                type="text"
                name="salary"
                disabled={disabled}
                className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300 text-right'
                value={payload.salary}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-6 mt-3'>
            <div>
              <label className='block text-sm font-medium text-gray-700 pt-2'>
                Take Home Pay
              </label>
            </div>
            <div>
              <input
                type="text"
                name="salary"
                disabled
                className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300 text-right'
                value={payload.total}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-6 mt-3'>
            <h5 className="text-lg font-medium leading-6 text-gray-900">Tunjangan</h5>
          </div>

          <div className='grid grid-cols-5 gap-6 mt-3'>
            {tunjangan && tunjangan.map(item => (
              <>
                <div className='col-span-2'>
                  <input
                    type="text"
                    name="name"
                    disabled={disabled}
                    placeholder='Name'
                    className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
                    value={item.name}
                    onChange={(e) => handleOnChangeTunjangan(e, tunjangan.indexOf(item))}
                  />
                </div>
                <div className='col-span-2'>
                  <input
                    type="text"
                    name="amount"
                    disabled={disabled}
                    placeholder='Amount'
                    className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300 text-right'
                    value={item.amount}
                    onChange={(e) => handleOnChangeTunjangan(e, tunjangan.indexOf(item))}
                  />
                </div>
                <div className='col-span-1'>
                  <button 
                    disabled={disabled}
                    className={disabled === true ? 'hidden' : 'bg-white mt-1'}
                    onClick={() => handleOnDeleteTunjangan(tunjangan.indexOf(item))}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
              </>
            ))}
          </div>

          <div className='grid grid-cols-1 gap-6 mt-3'>
            <button
              disabled={disabled}
              type="button"
              className={disabled === true ? 'hidden' : "inline-flex justify-center bg-white py-2.5 px-4 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium rounded-md  text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
              onClick={handleOnAddTunjangan}
            >
              Tambah Tunjangan
            </button>
          </div>

          <div className='grid grid-cols-2 gap-6 mt-3'>
            <h5 className="text-lg font-medium leading-6 text-gray-900">Penambahan</h5>
          </div>

          <div className='grid grid-cols-5 gap-6 mt-3'>
            {additions && additions.map(item => (
              <>
                <div className='col-span-2'>
                  <input
                    type="text"
                    disabled={disabled}
                    name="name"
                    placeholder='Name'
                    className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
                    value={item.name}
                    onChange={(e) => handleOnChangeAdditions(e, additions.indexOf(item))}
                  />
                </div>
                <div className='col-span-2'>
                  <input
                    type="text"
                    disabled={disabled}
                    name="amount"
                    className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300 text-right'
                    value={item.amount}
                    placeholder='Amount'
                    onChange={(e) => handleOnChangeAdditions(e, additions.indexOf(item))}
                  />
                </div>
                <div className='col-span-1'>
                  <button 
                    disabled={disabled}
                    className={disabled === true ? 'hidden' : 'bg-white mt-1'}
                    onClick={() => handleOnDeleteAdditions(additions.indexOf(item))}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
              </>
            ))}
          </div>

          <div className='grid grid-cols-1 gap-6 mt-3'>
            <button 
              type="button"
              disabled={disabled}
              className={disabled === true ? 'hidden' : "inline-flex justify-center bg-white py-2.5 px-4 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium rounded-md  text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
              onClick={handleOnAddAdditions}
            >
              Tambah Penambahan
            </button>
          </div>

          <div className='grid grid-cols-2 gap-6 mt-3'>
            <h5 className="text-lg font-medium leading-6 text-gray-900">Pengurangan</h5>
          </div>

          <div className='grid grid-cols-5 gap-6 mt-3'>
            {deductions && deductions.map(item => (
              <>
                <div className='col-span-2'>
                  <input
                    type="text"
                    disabled={disabled}
                    name="name"
                    placeholder='Name'
                    className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
                    value={item.name}
                    onChange={(e) => handleOnChangeDeductions(e, deductions.indexOf(item))}
                  />
                </div>
                <div className='col-span-2'>
                  <input
                    type="text"
                    disabled={disabled}
                    name="amount"
                    className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300 text-right'
                    value={item.amount}
                    placeholder='Amount'
                    onChange={(e) => handleOnChangeDeductions(e, deductions.indexOf(item))}
                  />
                </div>
                <div className='col-span-1'>
                  <button
                    disabled={disabled}
                    className={disabled === true ? 'hidden' : 'bg-white mt-1'}
                    onClick={() => handleOnDeleteDeductions(deductions.indexOf(item))}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
              </>
            ))}
          </div>

          <div className='grid grid-cols-1 gap-6 mt-3'>
            <button 
              type="button"
              disabled={disabled}
              className={disabled === true ? 'hidden' : "inline-flex justify-center bg-white py-2.5 px-4 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium rounded-md  text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
              onClick={handleOnAddDeductions}
            >
              Tambah Pengurangan
            </button>
          </div>

          <div className="py-3 mt-6 text-right">
            <button
              type="button"
              className="inline-flex justify-center ml-5 bg-white py-2.5 px-4 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium rounded-md  text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleOnCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              disabled={disabled}
              className={disabled === true ? 'hidden' : "inline-flex justify-center ml-5 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
              onClick={handleOnSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}