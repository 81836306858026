export function postPayroll(document) {
  return dispatch => {
    dispatch(getToken(document))
  }
}

function getToken(document) {
  return dispatch => {
    const tokenURL =
      'https://account.makaramas.com/auth/realms/MM_AIS/protocol/openid-connect/token';

    fetch(tokenURL, {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'client_credentials',
        client_id: 'payroll',
        client_secret: '6aa28a13-9570-4a34-bd36-554b8ba9c4c1',
        scope: 'profile email',
      }).toString(),
    })
      .then(res => res.json())
      .then(data => {
        dispatch(savePayroll(data, document));
      })
      .catch(e => {
        dispatch(postPayrollFailed(e));
      });
  };
}

function savePayroll(data, document) {
  if (document && document._id) {
    return dispatch => {
      dispatch(postPayrollStarted());
      const url = 'https://payroll.makaramas.com/api/document/edit/' + document._id;

      fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + data.access_token,
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(document)
      })
        .then(res => res.json())
        .then(data => {
          dispatch(postPayrollSuccess(data));
        })
        .catch(e => {
          dispatch(postPayrollFailed(e));
        });
    };
  }
  return dispatch => {
    dispatch(postPayrollStarted());
    const url = 'https://payroll.makaramas.com/api/document';

    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + data.access_token,
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(document)
    })
      .then(res => res.json())
      .then(data => {
        dispatch(postPayrollSuccess(data));
      })
      .catch(e => {
        dispatch(postPayrollFailed(e));
      });
  };
}

function postPayrollStarted() {
  return {
    type: 'POST_PAYROLL_STARTED',
  };
}

function postPayrollSuccess(document) {
  return {
    type: 'POST_PAYROLL_SUCCESS',
    payload: document,
  };
}

function postPayrollFailed(error) {
  return {
    type: 'POST_PAYROLL_FAILED',
    payload: {
      error,
    },
  };
}

export function clearPostPayroll() {
  return {
    type: 'CLEAR_PAYROLL',
  };
}

export function newDocument(document) {
  return {
    type: 'NEW_DOCUMENT',
    payload: document,
  }
}

export function editDocument(document, entries) {
  return {
    type: 'EDIT_DOCUMENT',
    payload: {document, entries}
  }
}

export function viewEntries(entries) {
  return {
    type: 'VIEW_ENTRIES',
    payload: entries,
  }
}

export function addEntries(entry) {
  return {
    type: 'ADD_ENTRIES',
    payload: entry,
  }
}

export function editEntries(entry) {
  return {
    type: 'EDIT_ENTRIES',
    payload: entry,
  }
}

export function removeEntries(entry) {
  return {
    type: 'REMOVE_ENTRIES',
    payload: entry
  }
}