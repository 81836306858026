const INITIAL_STATE = {
  documents: [],
  isLoading: true,
  error: null,
};

export default function getPayrollReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_PAYROLL_STARTED':
      return {
        ...state,
        isLoading: true,
      };

    case 'GET_PAYROLL_SUCCESS':
      return {
        ...state,
        isLoading: false,
        error: null,
        documents: action.payload,
      };

    case 'GET_PAYROLL_FAILED':
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case 'CLEAR_PAYROLL':
      return {
        ...state,
        isLoading: true,
        error: null,
        documents: [],
      };

    default:
      return state;
  }
}