export function getEmployee() {
  return dispatch => {
    dispatch(getToken())
  }
}

function getToken() {
  return dispatch => {
    const tokenURL =
      'https://account.makaramas.com/auth/realms/MM_AIS/protocol/openid-connect/token';

    fetch(tokenURL, {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'client_credentials',
        client_id: 'payroll',
        client_secret: '6aa28a13-9570-4a34-bd36-554b8ba9c4c1',
        scope: 'profile email',
      }).toString(),
    })
      .then(res => res.json())
      .then(data => {
        dispatch(fetchEmployee(data));
      })
      .catch(e => {
        dispatch(getEmployeeFailed(e));
      });
  };
}

function fetchEmployee(data) {
  return dispatch => {
    dispatch(getEmployeeStarted());
    const url = 'https://payroll.makaramas.com/api/employee';

    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + data.access_token,
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then(res => res.json())
      .then(data => {
        dispatch(getEmployeeSuccess(data));
      })
      .catch(e => {
        dispatch(getEmployeeFailed(e));
      });
  };
}

function getEmployeeStarted() {
  return {
    type: 'GET_EMPLOYEE_STARTED',
  };
}

function getEmployeeSuccess(employees) {
  return {
    type: 'GET_EMPLOYEE_SUCCESS',
    payload: employees,
  };
}

function getEmployeeFailed(error) {
  return {
    type: 'GET_EMPLOYEE_FAILED',
    payload: {
      error,
    },
  };
}

export function clearEmployee() {
  return {
    type: 'CLEAR_EMPLOYEE',
  };
}