import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux';

function PayrollList({disabled, handleOnEdit, handleOnDelete, entries}) {
  const [payload, setPayload] = useState([])

  useMemo(() => {
    if (entries) {
      setPayload(entries)
    }
  },[entries])
  
  return(
    <table className='min-w-full divide-y divide-gray-200'>
      <thead className='bg-gray-100 align-middle border-b boder-gray-200'>
        <tr className='h-12'>
          <th hidden scope='col'>ID</th>
          <th scope='col'>Nama</th>
          <th scope='col'>Gaji</th>
          <th scope='col'>Tunjangan</th>
          <th scope='col'>Penambahan</th>
          <th scope='col'>Pengurangan</th>
          <th scope='col'>Total</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {payload && payload.map(row => (
        <tr key={row.id} className='h-12 border-b'>
          <td hidden className='text-left'>{row.id}</td>
          <td className='text-center'>{row.name}</td>
          <td className='text-right'>Rp {row.salary ? row.salary.toLocaleString() : 0}</td>
          <td className='text-right'>Rp {row.tunjangan ? row.tunjangan.map(item => item.amount).reduce((a,b) => a + b).toLocaleString() : 0} </td>
          <td className='text-right'>Rp {row.additions ? row.additions.map(item => item.amount).reduce((a,b) => a + b).toLocaleString() : 0}</td>
          <td className='text-right'>Rp {row.deductions ? row.deductions.map(item => item.amount).reduce((a,b) => a + b).toLocaleString() : 0}</td>
          <td className='text-right'>Rp {row.total ? row.total.toLocaleString() : 0}</td>
          <td className='text-right'>
            <div className='grid grid-cols-3'>
              <div>
                <button onClick={() => handleOnEdit(row)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                </button>
              </div>
              <div >
                <button disabled={disabled} className={disabled === true ? 'hidden' : 'bg-white mt-1'} onClick={() => handleOnDelete(row)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
              </div>
            </div>
          </td>
        </tr>
        ))
      }
      </tbody>
    </table>
  )
}

function mapStateToProps(state) {
  return {
    entries: state.postPayroll.entries
  };
}

export default connect(mapStateToProps)(PayrollList);