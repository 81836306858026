export function approvePayroll(document) {
  return dispatch => {
    dispatch(getToken(document))
  }
}

function getToken(document) {
  return dispatch => {
    const tokenURL =
      'https://account.makaramas.com/auth/realms/MM_AIS/protocol/openid-connect/token';

    fetch(tokenURL, {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'client_credentials',
        client_id: 'payroll',
        client_secret: '6aa28a13-9570-4a34-bd36-554b8ba9c4c1',
        scope: 'profile email',
      }).toString(),
    })
      .then(res => res.json())
      .then(data => {
        dispatch(savePayroll(data, document));
      })
      .catch(e => {
        dispatch(approvePayrollFailed(e));
      });
  };
}

function savePayroll(data, document) {
    return dispatch => {
      dispatch(approvePayrollStarted());
      const url = 'https://payroll.makaramas.com/api/document/approve/' + document._id;

      fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + data.access_token,
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(document)
      })
        .then(res => res.json())
        .then(data => {
          dispatch(approvePayrollSuccess(data));
        })
        .catch(e => {
          dispatch(approvePayrollFailed(e));
        });
    };
  }

  function approvePayrollStarted() {
    return {
      type: 'APPROVE_PAYROLL_STARTED',
    };
  }
  
  function approvePayrollSuccess(document) {
    return {
      type: 'APPROVE_PAYROLL_SUCCESS',
      payload: document,
    };
  }
  
  function approvePayrollFailed(error) {
    return {
      type: 'APPROVE_PAYROLL_FAILED',
      payload: {
        error,
      },
    };
  }