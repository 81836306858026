const INITIAL_STATE = {
  employees: [],
  isLoading: true,
  error: null,
};

export default function getEmployeeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_EMPLOYEE_STARTED':
      return {
        ...state,
        isLoading: true,
      };

    case 'GET_EMPLOYEE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        error: null,
        employees: action.payload,
      };

    case 'GET_EMPLOYEE_FAILED':
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case 'CLEAR_EMPLOYEE':
      return {
        ...state,
        isLoading: false,
        error: null,
        employees: [],
      };

    default:
      return state;
  }
}