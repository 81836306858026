import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { approvePayroll } from '../../shared/actions/ApprovePayrollAction';
import { getEmployee } from '../../shared/actions/GetEmployeeAction';
import { clearPayroll, getPayroll } from '../../shared/actions/GetPayrollAction';
import { addEntries, clearPostPayroll, editDocument, editEntries, postPayroll, removeEntries, viewEntries } from '../../shared/actions/PostPayrollAction';
import DocumentList from './DocumentList';
import EntryForm from './EntryForm';
import PayrollList from './PayrollList';

function PayrollPage(props) {
  const [content, setContent] = useState()
  const [isView, setView] = useState(false)
  const [isEditEntry, setEditEntry] = useState(false)
  const userName = window.localStorage.getItem('userName')
  const userRole = window.localStorage.getItem('userRole')
  const [documentEntry, setDocEntry] = useState({
    year: new Date().getFullYear(),
    month: Number(new Date().getMonth() + 1),
    created_by: userName,
    updated_by: userName
  })
  const [modal, setModal] = useState('hidden mt-48 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center')
  const {isLoading, documents, document, employees} = props
  
  useEffect(() => {
    if (isLoading === true) {
      props.getPayroll()
      props.getEmployee()
    }
  },[isLoading, props])

  function handleOnView(item) {
    props.editDocument(item, item.entries)
    setView(true)
  }

  function handleOnEditEntry(item) {
    setContent(item)
    setView(false)
    setEditEntry(true)
  }

  function handleOnAddEntry() {
    setEditEntry(true)
    setView(false)
    setContent([])
  }

  function handleOnChangedEntry(e, tunjangan, additions, deductions, total) {
    if (e) {
      let name = e.target.name
      let value = e.target.value
      if (name === 'employee_id') {
        setContent({
          ...content,
          id: value,
          name: employees.filter(employee => employee._id === value).map(employee => employee.employee_name)[0]
        })
      } 
      else {
        setContent({
          ...content,
          [name]: Number(value),
          id: content.id ? content.id : employees.map(employee => employee._id)[0],
          name: content.name ? content.name : employees.map(employee => employee.employee_name)[0],
          total: tunjangan || additions || deductions ? content.total : Number(value)
        })
      }
    }
    if (tunjangan) {
      if (content.tunjangan) {
        setContent({
          ...content,
          tunjangan: tunjangan
        })
      }
      else {
        setContent(content.tunjangan = tunjangan)
      }
    }
    if (additions) {
      if (content.additions){
        setContent({
          ...content,
          additions: additions
        })
      }
      else {
        setContent(content.additions = additions)
      }
    }
    if (deductions) {
      if (content.deductions){
        setContent({
          ...content,
          deductions: deductions
        })
      }
      else {
        setContent(content.deductions = deductions)
      }
    }
    if (total) {
      setContent({
        ...content,
        total: total
      })
    }
  }
  
  function handleOnSaveEntry(){
    props.editEntries(content)
    setEditEntry(false)
    setView(true)
  }

  function handleOnRemoveEntry(item){
    props.removeEntries(item)
  }

  function handleOnCancelEntry(){
    setContent(null)
    setView(true)
    setEditEntry(false)
  }

  function handleOnEditDocument() {
    setModal('mt-48 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center')
  }

  function handleOnChangeModal(e) {
    setDocEntry({
      ...documentEntry,
      [e.target.name]: Number(e.target.value)
    })
  }
  
  function handleOnCloseModal() {
    setModal('hidden mt-48 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center')
  }

  function handleOnSaveModal() {
    props.postPayroll(documentEntry)
    setModal('hidden mt-48 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center')
    props.clearPostPayroll()
    props.clearPayroll()
    props.getPayroll()
  }

  function handleOnSaveDocument() {
    props.postPayroll(document)
    setContent(null)
    setView(false)
    setEditEntry(false)
    props.clearPostPayroll()
    props.clearPayroll()
    props.getPayroll()
  }

  function handleOnCancelDocument(){
    props.clearPayroll()
    props.clearPostPayroll()
    setContent(null)
    setView(false)
    setEditEntry(false)
  }

  function handleOnApproveDocument(item){
    item.approved_at = new Date().toLocaleString('fr-CA', {dateStyle: 'short'})
    item.approved_by = userName
    props.approvePayroll(item)
    props.getPayroll()
    setContent(null)
    setView(false)
    setEditEntry(false)
  }

  const month = [
    {id: 1, value: "Januari"},
    {id: 2, value: "Februari"},
    {id: 3, value: "Maret"},
    {id: 4, value: "April"},
    {id: 5, value: "Mei"},
    {id: 6, value: "Juni"},
    {id: 7, value: "Juli"},
    {id: 8, value: "Agustus"},
    {id: 9, value: "September"},
    {id: 10, value: "Oktober"},
    {id: 11, value: "November"},
    {id: 12, value: "Desember"}
  ]
  
  if (isView === true) {
    return(
      <>
        <PayrollList disabled={document.approved_at ? true : false} handleOnEdit={handleOnEditEntry} handleOnDelete={handleOnRemoveEntry} />
        <div className='grid grid-cols-1 gap-6 mt-3'>
            <button 
            type="button"
            disabled={document.approved_at ? true : false}
            className={document.approved_at ? 'hidden' : "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
            onClick={handleOnAddEntry}
          >
            Tambah Pegawai
          </button>
        </div>
        <div className="py-3 mt-6 text-right">
          <button
            type="button"
            className="inline-flex justify-center ml-5 bg-white py-2.5 px-4 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium rounded-md  text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={handleOnCancelDocument}
          >
            Cancel
          </button>
          <button
            type="button"
            className={document.approved_at ? 'hidden' : "inline-flex justify-center ml-5 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
            onClick={handleOnSaveDocument}
          >
            Save
          </button>
        </div>
      </>
    )
  }

  if (isEditEntry === true) {
    return (
      <EntryForm disabled={document.approved_at ? true : false} payload={content} employees={employees} handleOnCancel={handleOnCancelEntry} handleOnChange={handleOnChangedEntry} handleOnSave={handleOnSaveEntry} />
    )
  }

  else {
    return(
      <div>
        <div className='grid grid-cols-1 gap-6 mt-3 mb-3'>
          <button 
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={handleOnEditDocument}
          >
            Buat Dokumen Baru
          </button>
        </div>
        <DocumentList payload={documents} handleOnView={handleOnView} handleOnApprove={handleOnApproveDocument} />
        <div className={modal}>
          <div className="relative w-auto my-6 mx-auto max-w-sm">
            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
              <div className='flex items-start justify-between p-5 border-b border-solid border-gray-200 rounded-t'>
                <h3 className="text-3xl font-semibold">
                  Dokumen Payroll
                </h3>
                <button
                  className='p-1 ml-auto bg-transparent border-0 text-gray-300 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                  onClick={handleOnCloseModal}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="relative p-6 flex-auto">
                <div className='grid grid-cols-2 gap-6 mt-6'>
                  <div>
                    <label className='block text-sm font-medium text-gray-700 pt-2'>
                      Tahun
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="year"
                      value={documentEntry.year}
                      onChange={(e) => handleOnChangeModal(e)}
                      className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300 text-right'
                    />
                  </div>
                </div>

                <div className='grid grid-cols-2 gap-6 mt-6'>
                  <div>
                    <label className='block text-sm font-medium text-gray-700 pt-2'>
                      Bulan
                    </label>
                  </div>
                  <div>
                    <select
                      name="month"
                      className='block text-sm w-full font-medium text-gray-700'
                      value={documentEntry.month}
                      onChange={(e) => handleOnChangeModal(e)}
                    >
                      {month.map(entry => (
                        <option value={entry.id}>{entry.value}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="py-3 mt-6 text-right">
                  <button
                    type="button"
                    // disabled={disabled}
                    className="inline-flex justify-center ml-5 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={handleOnSaveModal}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapDispatchtoProps(dispatch) {
  return {
    getPayroll: () => {
      dispatch(getPayroll())
    },
    getEmployee: () => {
      dispatch(getEmployee())
    },
    clearPayroll: () => {
      dispatch(clearPayroll())
    },
    clearPostPayroll: () => {
      dispatch(clearPostPayroll())
    },
    postPayroll: (document) => {
      dispatch(postPayroll(document))
    },
    approvePayroll: (document) => {
      dispatch(approvePayroll(document))
    },
    editDocument: (document, entries) => {
      dispatch(editDocument(document, entries))
    },
    addEntries: (entry) => {
      dispatch(addEntries(entry))
    },
    viewEntries: (entries) => {
      dispatch(viewEntries(entries))
    },
    editEntries: (content) => {
      dispatch(editEntries(content))
    },
    removeEntries: (entry) => {
      dispatch(removeEntries(entry))
    }
  }
}

function mapStateToProps(state) {
  return {
    documents: state.getPayroll.documents,
    isLoading: state.getPayroll.isLoading,
    document: state.postPayroll.document,
    employees: state.getEmployee.employees,
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(PayrollPage);