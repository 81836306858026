export function postEmployee(employee) {
  return dispatch => {
    dispatch(getToken(employee))
  }
}

function getToken(employee) {
  return dispatch => {
    const tokenURL =
      'https://account.makaramas.com/auth/realms/MM_AIS/protocol/openid-connect/token';

    fetch(tokenURL, {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'client_credentials',
        client_id: 'payroll',
        client_secret: '6aa28a13-9570-4a34-bd36-554b8ba9c4c1',
        scope: 'profile email',
      }).toString(),
    })
      .then(res => res.json())
      .then(data => {
        dispatch(saveEmployee(data, employee));
      })
      .catch(e => {
        dispatch(postEmployeeFailed(e));
      });
  };
}

function saveEmployee(data, employee) {
  if (employee && employee._id) {
    return dispatch => {
      dispatch(postEmployeeStarted());
      const url = 'https://payroll.makaramas.com/api/employee/' + employee._id;

      fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + data.access_token,
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(employee)
      })
        .then(res => res.json())
        .then(data => {
          dispatch(postEmployeeSuccess(data));
        })
        .catch(e => {
          dispatch(postEmployeeFailed(e));
        });
    };
  }
  return dispatch => {
    dispatch(postEmployeeStarted());
    const url = 'https://payroll.makaramas.com/api/employee';

    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + data.access_token,
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(employee)
    })
      .then(res => res.json())
      .then(data => {
        dispatch(postEmployeeSuccess(data));
      })
      .catch(e => {
        dispatch(postEmployeeFailed(e));
      });
  };
}

function postEmployeeStarted() {
  return {
    type: 'POST_EMPLOYEE_STARTED',
  };
}

function postEmployeeSuccess(employee) {
  return {
    type: 'POST_EMPLOYEE_SUCCESS',
    payload: employee,
  };
}

function postEmployeeFailed(error) {
  return {
    type: 'POST_EMPLOYEE_FAILED',
    payload: {
      error,
    },
  };
}

export function clearEmployee() {
  return {
    type: 'CLEAR_EMPLOYEE',
  };
}