import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import approvePayrollReducer from './reducers/ApprovePayrollReducer';
import getEmployeeReducer from './reducers/GetEmployeeReducer';
import getPayrollReducer from './reducers/GetPayrollReducer';
import postEmployeeReducer from './reducers/PostEmployeeReducer';
import postPayrollReducer from './reducers/PostPayrollReducer';

const rootReducer = combineReducers({
  getPayroll: getPayrollReducer,
  getEmployee: getEmployeeReducer,
  postEmployee: postEmployeeReducer,
  postPayroll: postPayrollReducer,
  approvePayroll: approvePayrollReducer,
});

export default function configureStore() {
  return createStore(rootReducer, applyMiddleware(thunk));
}