import React from 'react'

export default function EmployeeForm({payload, handleOnCancel, handleOnChange, handleOnSave}) {
  
  return (
    <div className='md:grid md:grid-cols-3 md:gap-6'>
      <div></div>
      <div className='mt-12'>
        <div>
          <h1>
            Employee Form
          </h1>

          <div className='grid grid-cols-2 gap-6 mt-6'>
            <div>
              <label className='block text-sm font-medium text-gray-700 pt-2'>
                ID
              </label>
            </div>
            <div>
              <input
                type="text"
                disabled
                name="_id"
                className='block text-sm font-medium text-gray-700'
                placeholder='ID will be auto-assigned'
                value={payload && payload._id ? payload._id : undefined}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-6 mt-3'>
            <div>
              <label className='block text-sm font-medium text-gray-700 pt-2'>
                Nama Pegawai
              </label>
            </div>
            <div>
              <input
                type="text"
                name="employee_name"
                className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
                value={payload.employee_name}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-6 mt-3'>
            <div>
              <label className='block text-sm font-medium text-gray-700 pt-2'>
                Email Pegawai
              </label>
            </div>
            <div>
              <input
                type="email"
                name="email"
                className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
                value={payload.email}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-6 mt-3'>
            <div>
              <label className='block text-sm font-medium text-gray-700 pt-2'>
                Handphone Pegawai
              </label>
            </div>
            <div>
              <input
                type="text"
                name="phone"
                className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
                value={payload.phone}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-6 mt-3'>
            <div>
              <label className='block text-sm font-medium text-gray-700 pt-2'>
                NPWP Pegawai
              </label>
            </div>
            <div>
              <input
                type="text"
                name="npwp"
                className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
                value={payload.npwp}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-6 mt-3'>
            <div>
              <label className='block text-sm font-medium text-gray-700 pt-2'>
                Jumlah Tanggungan
              </label>
            </div>
            <div>
              <input
                type="text"
                name="dependents"
                className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
                value={payload.dependents}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-6 mt-3'>
            <div>
              <label className='block text-sm font-medium text-gray-700 pt-2'>
                Tanggal Bekerja
              </label>
            </div>
            <div>
              <input
                type="date"
                name="hired_date"
                className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
                value={payload.hired_date}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-6 mt-3'>
            <div>
              <label className='block text-sm font-medium text-gray-700 pt-2'>
                Tanggal Berhenti
              </label>
            </div>
            <div>
              <input
                type="date"
                name="termination_date"
                className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
                value={payload.termination_date}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>

          <div className="py-3 mt-6 text-right">
            <button
              type="button"
              className="inline-flex justify-center ml-5 bg-white py-2.5 px-4 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium rounded-md  text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleOnCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="inline-flex justify-center ml-5 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleOnSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}